@import url("../App.css");

/* Preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.preloader.pre-hide {
  visibility: hidden;
  opacity: 0;
}

.preloader-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.preloader-content img {
  width: 9em;
  max-width: 100%;
}

.preloader-content #pre {
  width: 2.5rem;
  margin-top: 1.5rem;
}

.preloader-content h6 {
  font-family: var(--font-medium);
  color: var(--dark-4);
  text-transform: uppercase;
}

/* Navbar */

.navbar {
  padding: 0.75em 0;
  margin: 35px 0;
}

.navbar.scrolled {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

/* Top bar */

.top-bar {
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
  height: 35px;
  background: var(--green);
  z-index: 10;
}

.top-bar ul {
  display: flex;
  align-items: center;
  gap: 30px;
}

.top-bar ul li {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--white);
  line-height: 1.2;
}

.top-bar ul li a {
  font-family: var(--font-medium);
  color: currentColor;
}

.top-bar ul li ion-icon {
  font-size: 16px;
}

/* Call to Action */

.cta {
  position: relative;
  z-index: 2;
  /* background-image: linear-gradient(
    to left,
    rgba(67, 233, 123, 0.65) 0%,
    rgba(56, 249, 215, 0.5) 100%
  ); */
  background-color: var(--green-light-1);
  padding: 3.5rem 0;
}

.cta::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: url("../../../images/HomeEl.svg");
  background-size: 90%;
  background-position: top center;
  width: 100%;
  height: 100%;
  opacity: 0.85;
}

.cta-content {
  position: relative;
  z-index: 2;
}

.cta #cta-tag {
  display: inline-block;
  font-family: var(--font-bold);
  text-transform: uppercase;
  color: var(--green-1);
  background-color: var(--white);
  padding: 0.25em 0.65em;
  border-radius: 0.35em;
  margin-bottom: 1em;
  letter-spacing: 0.15em;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
}

.cta .contact-us-btn {
  display: flex;
  align-items: center;
  gap: 7px;
  background-color: var(--black);
  border: none;
  color: var(--white);
  margin-top: 1.75em;
}

.cta .contact-us-btn img {
  width: 16px;
  transition: 0.2s ease-in-out transform;
}
.cta .contact-us-btn:hover img {
  transform: translate(3px, -3px);
}

.cta img {
  display: block;
  max-width: 80%;
  width: 25rem;
  margin: 0 auto;
}

.cta .cta-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 95%;
  margin: 3em 0 0;
}

.cta .cta-form .form-box {
  position: relative;
  width: 100%;
}

.cta .cta-form .form-box input.form-control {
  font-family: var(--font-medium);
  font-size: 1.1em;
  background: none;
  border: none;
  border-bottom: 2px solid var(--dark-1);
  border-radius: 0px;
  color: var(--black);
  padding-left: 0;
}

.cta .cta-form .form-box input.form-control::placeholder {
  color: currentColor;
  position: relative;
  top: 0;
}

.cta .cta-form .form-box label {
  font-family: var(--font-medium);
  font-size: 1.1em;
  position: absolute;
  top: 6px;
  transition: 0.15s ease-in-out all;
}

.cta .cta-form .form-box input.form-control:focus + label,
.cta .cta-form .form-box input.form-control:not([value=""]) ~ label {
  font-size: 0.9em;
  top: -15px;
  letter-spacing: 0.05em;
}

/* Form Overlay */

.form-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100vh;
  background: var(--white);
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  transition: all 0.4s ease-in-out;
  background-image: url("../../../images/HomeEl.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-color: #fefefe;
}

.form-overlay.form-open {
  visibility: visible;
  opacity: 1;
}

.form-overlay-content {
  display: block;
  margin: 0 auto;
  width: 80%;
  height: 70vh;
  padding: 4rem 0;
  overflow: hidden;
}

.form-overlay-content .form-slide-container {
  width: 100%;
  height: 100%;
  /* background: #fafafa; */
  padding: 2rem;
}

.form-box-container {
  height: 400%;
}

.form-slide-container .form-box {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.85s ease-in-out all;
  top: 0;
}

.form-slide-container .form-box.move-up {
  top: -100%;
}

.form-slide-container .form-box.move-up-2 {
  top: -200%;
}

.form-slide-container .form-box.move-up-3 {
  top: -300%;
}

.form-slide-container .form-box input {
  border: none;
  border-bottom: 2px solid var(--black);
  border-radius: 0px;
  padding: 0.35rem 0;
  margin: 1.2rem 0;
  font-size: 1.25rem;
  color: var(--dark-1);
  font-family: var(--font-medium);
  background: none;
}

#project_textarea {
  border: 2px solid var(--black);
  padding: 1.2rem;
  margin: 1.5rem 0 0.25rem 0;
}

.first-section h3 {
  font-family: var(--font-medium);
  font-size: 1.35rem;
  margin: 0.75rem 0;
  color: var(--dark-3);
}

.close_form_btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.form-slide-container .form-box .form-btn {
  display: flex;
  align-items: center;
  font-size: 1.15rem;
  border: none;
  background-color: var(--dark-2);
  padding: 0.35rem 1.2rem;
  transform: translateY(20px);
}

.form-slide-container .form-box .form-btn img {
  width: 16px;
  margin-left: 0.75rem;
}

.close_form_btn span {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--bs-secondary);
}

/* End of Form Overlay */

/* Testimonials */

section.testimonial-section {
  position: relative;
  padding: 5em 0 4em;
  text-align: center;
}

.testimonial-section .tag {
  color: var(--green-1);
  text-transform: uppercase;
  font-family: var(--font-bold);
  font-size: 0.8em;
  letter-spacing: 0.125em;
}

.testimonial-section .testimonial-content {
  position: relative;
  z-index: 2;
}

/* .testimonial-section .testimonial-content::after {
  content: "\f0d7";
  position: absolute;
  bottom: -30px;
  right: 25%;
  z-index: 2;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.25em;
  color: #777;
  opacity: 0.55;
} */

.testimonial-section .testimonial_bg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 65vh;
  opacity: 0.65;
}

.testimonial-section .testimonial-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 30px;
  width: 100%;
  padding: 1em;
  background: none;
}

.testimonial-section .testimonial-wrap::-webkit-scrollbar {
  width: 0px;
}

.testimonial-section .tml-item {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  height: calc(100% - 1.5rem);
  padding: 1.5em 2em;
  margin: 0 0 1.5rem 0;
  border-radius: 0.65rem;
  background-color: var(--white);
  box-shadow: 0rem 0.25rem 1.75rem rgba(30, 34, 40, 0.07);
}

.testimonial-section .tml-item::before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  font-size: 3em;
  color: var(--dark-5);
  opacity: 0.1;
  position: absolute;
  top: 0.2em;
  left: 1rem;
  z-index: 1;
}

.testimonial-section .tml-item img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.testimonial-section .tml-item .tml-cont {
  display: flex;
  flex-direction: column;
}

.testimonial-section .tml-item .tml-cont p {
  font-family: var(--font-medium);
  font-size: 0.9rem;
  margin: 0.2em 0 0 0;
  padding-bottom: 4rem;
  color: var(--dark-3);
  line-height: 1.8;
  text-align: left;
  letter-spacing: normal;
}

.testimonial-section .tml-item .tml-cont span {
  font-family: var(--font-medium);
  display: block;
  text-align: left;
  color: var(--dark-1);
}

.testimonial-section .tml-item .tml-cont span[data-desg] {
  font-family: inherit;
  font-size: 0.88em;
  color: var(--dark-5);
  letter-spacing: 0.15px;
}

.testimonial-section .tml-item .tml-name {
  position: absolute;
  bottom: 1.5rem;
}

/* Footer */

footer {
  position: relative;
  padding: 3.5rem 1rem 2rem;
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.97),
      rgba(255, 255, 255, 0.97)
    ),
    url("../../../images/Contact.svg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center 50%;
}

footer img {
  width: 90px;
}

footer h6 {
  font-family: var(--font-bold);
  margin: 0 0 0.25em 0;
}

footer p {
  font-size: 1em;
  line-height: 1.5;
  width: 80%;
}

footer .footer-links li {
  padding-bottom: 0.5em;
}

footer .footer-links li a {
  color: var(--dark-4);
}

footer .footer-links li a:hover {
  color: var(--green-1);
}

footer .footer-contact-details img {
  width: 20px;
  margin-right: 0.65em;
}

footer .footer-contact-details p a {
  color: var(--black);
}

footer .footer-contact-details p a {
  display: inline-flex;
  color: var(--black);
}

footer .social-links {
  display: flex;
  align-items: center;
  gap: 5px;
}

footer .social-links li img {
  max-width: 100%;
  width: 1.25em;
}

footer .copy-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 0 0;
  margin: 2em 0 0;
  border-top: 1px solid var(--light-1);
}

footer .copy-text span {
  font-size: 0.95em;
}

/* Go To Top Button */

.go_top {
  width: 2vw;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 2em;
  transform: translateX(-50%);
  cursor: pointer;
}

.rotate_load {
  animation: rotate 0.5s ease-in-out infinite forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* End of Footer */

/* Project Header */

.project-header {
  position: relative;
  width: 100%;
  padding: calc(4em + 98px) 0 5em 0;
  background-color: var(--green-light-1);
  background-image: url("../../../images/HomeEl.svg");
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: polygon(0 0, 100% 0%, 100% 97%, 0 85%);
}

.project-header h3 {
  font-size: 1.35rem;
  margin: 0.65em 0 0;
}

.pro-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 3em; */
}

.project-header-img {
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  right: 2em;
  z-index: 2;
  width: 170px;
}

.project-header-img img {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

/* Breadcrumbs */

.breadcrumbs {
  width: 100%;
}

.breadcrumbs span {
  line-height: 1;
  color: var(--dark-4);
}

.breadcrumbs #bcFor {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1.1em;
  opacity: 0.6;
}

/* Bootstrap override */

.navbar-light .navbar-nav .nav-link {
  padding: 0.25rem 0.5rem;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  position: relative;
  color: var(--black);
  font-family: var(--font-medium);
}

.navbar-light .navbar-nav .nav-link:last-child.active {
  color: var(--black);
}

.navbar-light .navbar-nav .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: (0 + 8px);
  width: 25px;
  height: 3px;
  background-color: var(--green);
}

.nav-item {
  font-size: 1.15em;
  padding-right: 1.15em;
  padding-left: 0;
}

.nav-item:last-child {
  padding: 0;
}

#contactLink {
  border: 2px solid var(--blue);
  border-radius: 0.35rem;
  padding: 0;
}

#contactLink .nav-link {
  font-family: var(--font-bold);
  color: var(--blue);
}

#contactLink .nav-link.active::before {
  background-color: transparent;
}

.container {
  padding-inline: 0;
}

/* Video Embed */

section.video-embed {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  min-height: 100vh;
  background: #f8f9fa;
}

section.video-embed .video-wrap {
  padding: 110px 0 0;
}

section.video-embed .video-wrap h4 {
  margin-bottom: 30px;
  text-align: center;
}

section.video-embed .video-wrap .video-wrap-iframe {
  width: 100%;
  min-width: 80vw;
  height: 560px;
}

/* End of Video Embed */

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 85%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .form-overlay {
    background-size: cover;
    background-position: 30px -130px;
  }
  .form-overlay-content {
    height: 100vh;
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .form-overlay {
    background-size: cover;
    background-position: 30px -130px;
  }

  .form-overlay-content {
    height: 90vh;
  }
}

@media only screen and (max-width: 768px) {
  .nav-item {
    padding: 7px 0;
  }
  .container {
    padding: 0.5em 1em;
  }

  /* CTA */

  .cta {
    padding-inline: 1em;
  }

  #cta-tag {
    font-size: 0.8em;
  }

  .cta .cta-form {
    flex-direction: column;
    width: 100%;
  }

  .cta .cta-form .form-box {
    padding-bottom: 1.25em;
  }

  .cta .contact-us-btn {
    margin: 1.45em auto 0;
  }

  .cta .image-holder {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 3.5rem 0 0;
  }

  .form-overlay {
    background-size: cover;
    background-position: -70px bottom;
  }

  .form-overlay-content {
    width: 100%;
    height: 95vh;
  }

  .form-overlay-content .form-slide-container {
    padding: 1rem;
  }

  .form-slide-container .form-box .form-btn {
    font-size: 0.95rem;
  }

  .first-section h3 {
    font-size: 1.15rem;
  }

  .first-section h6 {
    font-size: 0.9rem;
  }

  #project_textarea {
    padding: 0.75rem;
  }

  .project-header {
    padding: 9em 0 5em 1em;
  }

  .pro-header-content {
    margin: 30px 0 0;
  }

  /* Testimonial */

  section.testimonial-section {
    position: relative;
    padding: 4em 0.25em;
    background-color: var(--light-3);
  }

  section.testimonial-section p {
    font-size: 1.05em;
  }

  .testimonial-section .testimonial-wrap {
    width: 100%;
    height: 100%;
    padding: 1.25em 0;
  }

  .testimonial-section .testimonial-wrap .tml-item {
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .testimonial-section .tml-item .tml-cont p {
    font-size: 1em;
  }

  section.video-embed {
    padding-inline: 10px;
  }

  section.video-embed .video-wrap {
    padding-top: 20px;
  }

  section.video-embed .video-wrap .video-wrap-iframe {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 215px;
  }

  /* Footer */

  footer p {
    width: 100%;
  }

  footer .copy-text {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .go_top {
    display: none;
  }
}
